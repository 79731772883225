import React from "react";
import SEO from "components/seo";
import {
  ContactForm,
  ContentPhoto,
  FullPhoto,
  Gallery,
  Header,
  Hero,
  Icons,
  Layout,
  Logo,
  Navigation,
  TextBox,
  WhatWeDid,
} from "components/CaseLayout";

import { NYKART } from "shared/Types/cases";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";

const Nykart = () => {
  const data = useCaseData(NYKART);
  return (
    <Layout>
      <SEO title={data.title} description={data.seo} />
      <Hero data={data.hero} />
      <Navigation type={NYKART} />
      <WhatWeDid data={data.what_we_did} />
      <Logo data={data.logo.first} />
      <FullPhoto data={data.full_images.first} />
      <Header data={data.headers.first} />
      <ContentPhoto data={data.content_images.first} />
      <TextBox data={data.text_boxes.first} />
      <Icons color={data.color} data={data.icons} />
      <FullPhoto data={data.full_images.second} />
      <Header data={data.headers.second} />
      <ContentPhoto content="1100px" data={data.content_images.second} />
      <TextBox data={data.text_boxes.second} />
      <ContentPhoto content="1100px" data={data.content_images.third} />
      <ContentPhoto content="1100px" data={data.content_images.fourth} />
      <TextBox data={data.text_boxes.third} />
      <FullPhoto data={data.full_images.third} />
      <FullPhoto data={data.full_images.fourth} />
      <FullPhoto data={data.full_images.fifth} />
      <TextBox data={data.text_boxes.fourth} />
      <FullPhoto data={data.full_images.sixth} />
      <TextBox data={data.text_boxes.fifth} />
      <Gallery data={data.gallery.first} />
      <ContactForm />
    </Layout>
  );
};

export default Nykart;
